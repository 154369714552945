import React, { useEffect, useRef, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Paper from "@material-ui/core/Paper";
//import Divider from "@material-ui/core/Divider";
import BandTypeSelect from "../UI/BandTypeSelect";
import BandwidthSlider from "../UI/BandwidthSlider";
import ToggleButton from "@material-ui/lab/ToggleButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Tracks from "./Tracks";
import MasterGain from "../UI/MasterGain";
import PauseIcon from "@material-ui/icons/Pause";
import Button from "@material-ui/core/Button";

const drawerWidth = 275;

export default function EqControls(props) {
  const upload = useRef(null);
  const { high, mid, low } = props.spectrumHeights;
  const [spectrumHeight, setSpectrumHeight] = useState(props.spectrumHeight);
  const handleSpectrumHeightChange = (height) => {
    setSpectrumHeight(height);
    window.amplitudeHeightDiff = height;
    localStorage.setItem("spectrumHeight", height);
  };

  const [midSpectrumButtonHeight, setMidSpectrumButtonHeight] = useState(
    document.documentElement.clientHeight / 2
  );
  useEffect(() => {
    window.addEventListener("resize", (e) =>
      setMidSpectrumButtonHeight(document.documentElement.clientHeight / 2)
    );
    window.addEventListener("orientationchange", (e) =>
      setMidSpectrumButtonHeight(document.documentElement.clientHeight / 2)
    );
  }, []);

  const [inputFocused, setInputFocused] = useState(false);

  const [playable, setPlayable] = useState(true);
  const playableRef = useRef(playable);
  useEffect(() => {
    playableRef.current = playable;
  }, [playable]);

  document.addEventListener(
    "keydown",
    (e) => {
      if (e.key === " ") {
        const playButton = document.getElementById("play-button");
        if (playButton !== document.activeElement) {
          e.preventDefault();
          playButton.focus();
          playableRef.current && playButton.click();
        }
      }
    },
    false
  );

  const [isMobile, setIsMobile] = useState(false);

  const uploadDisabledState = useMemo(() => {
    return isMobile ? props.playing : false;
  }, [isMobile, props.playing]);

  const useStyles = makeStyles((theme) => ({
    root: {
      fontWeight: "bold",
      fontDisplay: "optional",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      textAlign: "center",
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    tracks: {
      width: drawerWidth,
      background: "#00050c",
      color: "#fff",
      overflowX: "hidden",
      fontDisplay: "optional",
    },
    zeroGravityEQ: {
      display: "flex",
      justifyContent: "center",
      ...theme.mixins.toolbar,
      color: props.spectrumClass,
      fontDisplay: "optional",
    },
    divider: {
      background: "#dd00ff",
    },
    filterContainer: {
      paddingTop: "2px",
      paddingBottom: "2px",
      fontDisplay: "optional",
    },
    trackDeck: {},
    loop: {
      background: "#0B0B14",
      color: "#ff0000",
      top: "0px",
      marginBottom: "18px",
      transition: "box-shadow 0.1s ease, outline 0.1s ease",
      outline: `1px solid rgba(119, 7, 7, 0.7)`,
      "&:hover": {
        background: "#0B0B14",
        outline: `1px solid rgba(255, 0, 0, 0.7)`,
      },
      "&:focus": {
        outline: `1px solid rgba(255, 0, 0, 0.9)`,
      },
      cursor: props.looping && props.loopCountdown >= 0 ? "wait" : "pointer",
      // boxShadow:
      //   "red 0px 0px 1px 0px, red 0px 0px 1px 0px, red 0px 0px 1px 0px",
    },
    upload: {
      background: "#000012",
      color: !uploadDisabledState ? "#bbbfb3" : "#bbbfb370",
      margin: "-4px",
    },
    fileWrapper: {
      marginTop: "2px",
      borderRadius: "3px",
      outline:
        !uploadDisabledState &&
        (inputFocused ? `1px solid #0050c9` : `1px solid #bbbfb370`),
      boxShadow: inputFocused ? `0 0 0 3px #fff` : undefined,
      "&:hover": !uploadDisabledState && {
        outline: !inputFocused ? `1px solid #adff00` : `1px solid #adff00`,
        outlineOffset: "1px",
        boxShadow: !inputFocused && `0px 0px 7px 0px #adff00`,
      },
      transition:
        "outline 0.12s ease-in-out, box-shadow 0.12s ease-in-out, outline-offset 0.12s ease-in-out",
    },
    playButton: {
      background: "#000012",
      marginBottom: "1rem",
      border: "1px solid green",
      color: "#00ff00",
      transition: "box-shadow 0.1s ease, outline 0.1s ease",
      "&:hover": {
        boxShadow:
          "#00ff00 0px 0px 1px 0px, #00ff00 0px 0px 1px 0px, #00ff00 0px 0px 1px 0px",
        border: "1px solid #00ff00",
      },
      "&:focus": {
        boxShadow:
          "#00ff00 0px 0px 1px 0px, #00ff00 0px 0px 1px 0px, #00ff00 0px 0px 1px 0px",
      },
    },
    playIcon: { color: playable ? "#00ff00" : "#005500" },
    pauseIcon: { color: "#99cc99" },
    player: {
      color: "white",
      background: "#0f0f0f",
    },
    footer: {
      color: "#8ce7f630",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      textDecoration: "none",
      fontSize: "12px",
    },
    spectrumHeightHigh: {
      backgroundColor: "rgb(0 6 7 / 50%)",
      position: "absolute",
      zIndex: 500,
      top: 72,
      right: 280,
      border:
        spectrumHeight === high
          ? "1.375px solid #00ff00"
          : ".7px solid #004343",
      color: spectrumHeight === high ? "#00ff00 " : "#004343",
      "&:hover": {
        backgroundColor: "rgb(0 6 7 / 50%)",
        color: "#00ff00",
      },
      height: 47,
    },
    spectrumHeightMid: {
      backgroundColor: "rgb(0 6 7 / 50%)",
      position: "absolute",
      zIndex: 500,
      top: midSpectrumButtonHeight + 14,
      right: 280,
      border:
        spectrumHeight === mid ? "1.375px solid #00ff00" : ".7px solid #004343",
      color: spectrumHeight === mid ? "#00ff00 " : "#004343",
      "&:hover": {
        backgroundColor: "rgb(0 6 7 / 50%)",
        color: "#00ff00",
      },
      height: 45,
    },
    spectrumHeightLow: {
      backgroundColor: "rgb(0 6 7 / 50%)",
      position: "absolute",
      zIndex: 500,
      bottom: 6.5,
      right: 280,
      border:
        spectrumHeight === low ? "1.375px solid #00ff00" : ".7px solid #004343",
      color: spectrumHeight === low ? "#00ff00 " : "#004343",
      "&:hover": {
        backgroundColor: "rgb(0 6 7 / 50%)",
        color: "#00ff00",
      },
      height: 43,
    },
  }));

  const classes = useStyles();

  const [highSpectrumHover, setHighSpectrumHover] = useState(false);
  const [midSpectrumHover, setMidSpectrumHover] = useState(false);
  const [lowSpectrumHover, setLowSpectrumHover] = useState(false);

  useEffect(() => {
    // Function to determine if the user is on a mobile device
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const mobileUA = /android|iPad|iPhone|iPod/i.test(userAgent);
      const mobileMediaQuery = window.matchMedia("(max-width: 767px)").matches;
      setIsMobile(mobileUA || mobileMediaQuery);
    };

    // Check device type on component mount
    checkIfMobile();
  }, []);

  const [initialUpload, setInitialUpload] = useState(true);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={true}
        classes={{
          paper: classes.tracks,
        }}
      >
        <div
          className={classes.zeroGravityEQ}
          onClick={() => window.location.reload()}
        >
          <h2>ZERO GRAVITY</h2>
        </div>
        {/* <Divider className={classes.divider} /> */}

        <div className={classes.filterContainer}>
          <div style={{ justifyContent: "center" }}>
            <BandTypeSelect
              filterType={props.filterType}
              handleFilterChange={props.handleFilterChange}
              spectrumClass={props.spectrumClass}
            />
          </div>
          <span id="bandwidth">bandwidth</span>
          <BandwidthSlider
            qSliderValue={props.qSliderValue}
            handleQChange={props.handleQChange}
            filterType={props.filterType}
          />
          <span id="makeup-gain">makeup gain</span>
          <MasterGain
            masterGainValue={props.gain.current.value}
            masterGainSliderChange={props.adjustGain}
            gain={props.gain}
            masterGain={props.masterGain}
          />
          <br />
          <Button
            variant="contained"
            size="small"
            key={1}
            onClick={() => {
              if (!props.looping || props.loopCountdown < 0) props.toggleLoop();
            }}
            className={`${classes.loop} loop`}
            aria-label="Loop"
          >
            {props.looping === true ? (
              props.loopCountdown >= 0 ? (
                props.loopCountdown
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#dddddd"
                  stroke="#dddddd"
                  viewBox="0 0 100 100"
                  height="20"
                >
                  <g>
                    <path d="M5 56C4 25 7 21 18 21h41V3l23 24-22 22V32H15v25H5zM95 45c0 23 2 36-13 36H40v17L18 75l22-22-1 18h45V45h10z"></path>
                  </g>
                </svg>
              )
            ) : (
              "🔴"
            )}
          </Button>
        </div>

        <Paper className={classes.player} elevation={3} square>
          <h2 className={classes.trackDeck}>TRaCK DECK</h2>
          <ToggleButton
            aria-label="Play / Pause"
            size="medium"
            className={classes.playButton}
            onClick={async () => {
              if (props.playing) setPlayable(false);
              await props.togglePlayButton();
              if (props.context.current.state === "suspended") {
                setTimeout(() => {
                  setPlayable(true);
                }, 1125);
              }
            }}
            id="play-button"
            value={props.playing}
            disabled={!playable}
          >
            {props.playing === false ? (
              <PlayArrowIcon className={classes.playIcon} />
            ) : (
              <PauseIcon className={classes.pauseIcon} />
            )}
          </ToggleButton>
          <br />
          <span className={`${classes.fileWrapper} file-wrapper`}>
            <input
              type="file"
              name="upload"
              ref={upload}
              accept="audio/mpeg, audio/wav, audio/ogg, audio/x-m4a, audio/flac, audio/aiff, .mp3, .wav, .ogg, .m4a, .flac, .aif, .aiff"
              onClick={() => {
                if (isMobile && initialUpload) {
                  setInitialUpload(false);
                  const bufferAudio_m4a = new Audio("/silence.m4a");
                  bufferAudio_m4a.loop = true;
                  bufferAudio_m4a.play();
                }
                upload.current.value = null;
              }}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  // Check if the file type is audio
                  if (file.type.startsWith("audio/")) {
                    // Optionally, you can also check for file size here
                    // if (file.size <= MAX_SIZE) {
                    props.handleTrackInput(file);
                    // } else {
                    //   alert("File is too large.");
                    // }
                  } else {
                    alert("File could not be played.");
                  }
                }
              }}
              disabled={uploadDisabledState}
              aria-label="Upload audio"
              id="upload-file"
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
            <span className={`${classes.upload} upload`}>
              <svg
                fill={!uploadDisabledState ? "#c8d7a8" : "#bbbfb370"}
                width="20px"
                height="20px"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                stroke={!uploadDisabledState ? "#c8d7a8" : "#bbbfb370"}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <title>upload</title>
                  <path d="M21.6 8h-11l-6.6 9v5c0 1.104 0.896 2 2 2h20c1.104 0 2-0.896 2-2v-5l-6.4-9zM22.465 17.022l-2.052 3.003-8.589-0.020-2.201-2.995-4.086-0.023 5.662-7.975h9.801l5.6 7.975-4.135 0.035zM13.125 14l2.438 0.062v3.938c0 0.553 0.385 1 0.938 1 0.552 0 0.875-0.447 0.875-1v-3.938l2.436-0.062-3.312-3.812-3.375 3.812z"></path>{" "}
                </g>
              </svg>
              &nbsp;
              <label htmlFor="upload-file">choose file</label>
            </span>
          </span>
          <Tracks
            handleTrackInput={props.handleTrackInput}
            playingTrack={props.playingTrack}
            togglePlayButton={props.togglePlayButton}
            playing={props.playing}
            spectrumClass={props.spectrumClass}
          />
        </Paper>

        <a
          href="https://github.com/mattfsourcecode"
          className={classes.footer}
          target="_blank"
          rel="noopener noreferrer"
        >
          © mattfsourcecode
        </a>
      </Drawer>
      <>
        <Button
          id="spectrum-button"
          onClick={() => {
            handleSpectrumHeightChange(high);
          }}
          className={`spectrum-button ${classes.spectrumHeightHigh}`}
          aria-label="Large Spectrum"
          onMouseEnter={() => setHighSpectrumHover(true)}
          onMouseLeave={() => setHighSpectrumHover(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            data-credit="https://www.svgrepo.com/author/swmansion/"
            data-license="https://www.svgrepo.com/page/licensing/#CC%20Attribution"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M12 7v10m-3-7v4M6 8v8m-3-5v2m12-9v16m3-11v6m3-4v2"
              stroke={
                spectrumHeight === high || highSpectrumHover
                  ? "#00ff00"
                  : "#002222"
              }
            ></path>
          </svg>
        </Button>
        <Button
          id="spectrum-button"
          onClick={() => {
            handleSpectrumHeightChange(mid);
          }}
          className={`spectrum-button ${classes.spectrumHeightMid}`}
          aria-label="Medium Spectrum"
          onMouseEnter={() => setMidSpectrumHover(true)}
          onMouseLeave={() => setMidSpectrumHover(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            data-credit="https://www.svgrepo.com/author/swmansion/"
            data-license="https://www.svgrepo.com/page/licensing/#CC%20Attribution"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M12 9v6m-3-4v2m-3-3v4m-3-3v2m12-7v12m3-8v4m3-3v2"
              stroke={
                spectrumHeight === mid || midSpectrumHover
                  ? "#00ff00"
                  : "#002222"
              }
            ></path>
          </svg>
        </Button>
        <Button
          id="spectrum-button"
          onClick={() => {
            handleSpectrumHeightChange(low);
          }}
          className={`spectrum-button ${classes.spectrumHeightLow}`}
          aria-label="Small Spectrum"
          onMouseEnter={() => setLowSpectrumHover(true)}
          onMouseLeave={() => setLowSpectrumHover(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            data-credit="https://www.svgrepo.com/author/swmansion/"
            data-license="https://www.svgrepo.com/page/licensing/#CC%20Attribution"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M12 10v4m-3-3v2m-3-2v2m-3-2v2m12-2v2m3-2v2m3-2v2"
              stroke={
                spectrumHeight === low || lowSpectrumHover
                  ? "#00ff00"
                  : "#002222"
              }
            ></path>
          </svg>
        </Button>
      </>
    </div>
  );
}
