/* eslint-disable no-restricted-globals */
import React from "react";
import Matter from "matter-js";
import MakeSound from "./MakeSound";
import Band from "../../sprites/band.svg";
import SpaceShip from "../../sprites/space-ship.svg";
import Recycle from "../../sprites/recycle.svg";
import Diagram from "../../sprites/diagram.svg";
import JS from "../../sprites/js.svg";
import Rose from "../../sprites/rose.svg";
// import Cactus from "../../sprites/cactus.svg";
import DJ from "../../sprites/dj.svg";
import Speaker from "../../sprites/speaker.svg";
import LegalDialog from "../Utility/LegalDialog";
import { logoBreakpoint } from "../Utility/AppBar";

// const Matter = window.Matter;

class MatterScene extends React.Component {
  constructor(props) {
    super(props);
    this.loopCountdownInit = 4;
    this.liveRender = {};
    this.state = {
      audioContext: {},
      source: {},
      pathCopy: [],
      currentRow: 1,
      frequencyBandArray: [...Array(2000).keys()],
      audioData: {},
      cursor: "default",
      loopCountdown: this.loopCountdownInit,
      width: document.documentElement.clientWidth - 275,
      height: document.documentElement.clientHeight,
    };
    this.bandRadius = 40;
    this.bandRestitution = 0.83;
    this.wallBottom = Matter.Bodies.rectangle(
      this.state.width / 2,
      this.state.height + 300,
      screen.width * 2,
      600,
      {
        isStatic: true,
        render: { fillStyle: "primary" },
      }
    );
    this.wallRight = Matter.Bodies.rectangle(
      this.state.width + 525,
      this.state.height / 2,
      1050,
      screen.height * 2,
      {
        isStatic: true,
        render: { fillStyle: "primary" },
      }
    );
    this.wallTop = Matter.Bodies.rectangle(
      document.documentElement.clientWidth / 2,
      -435,
      screen.width * 2,
      1000,
      {
        isStatic: true,
        render: { fillStyle: "primary" },
      }
    );
    this.wallLeft = Matter.Bodies.rectangle(
      -500,
      this.state.height / 2,
      1000,
      screen.height * 2,
      {
        isStatic: true,
        render: { fillStyle: "primary" },
      }
    );
  }

  removeBodies() {
    Matter.World.remove(this.liveRender.engine.world, this.FreqBand);
  }

  addBodies(one) {
    Matter.World.add(this.liveRender.engine.world, one);
  }
  // add revolute multi-body constraint

  trySuggestRotate() {
    setTimeout(() => {
      if (
        parseInt(
          window.getComputedStyle(document.querySelector("#scene")).width
        ) < 110
      ) {
        window.alert(
          "Please rotate your device to landscape mode for an optimal experience."
        );
      }
    }, 500);
  }

  componentDidMount() {
    this.renderScene();
    this.trySuggestRotate();
    window.addEventListener("resize", () => {
      this.setState({
        width: document.documentElement.clientWidth - 275,
        height: document.documentElement.clientHeight,
      });
      Matter.Body.setPosition(this.wallBottom, {
        x: this.state.width / 2,
        y: this.state.height + 300,
      });
      Matter.Body.setPosition(this.wallRight, {
        x: this.state.width + 525,
        y: this.state.height / 2,
      });
      Matter.Body.setPosition(this.wallTop, {
        x: this.state.width / 2,
        y: -435,
      });
      Matter.Body.setPosition(this.wallLeft, {
        x: -500,
        y: this.state.height / 2,
      });
      this.liveRender.canvas.width = this.state.width;
      this.liveRender.canvas.height = this.state.height;
    });
    window.addEventListener("orientationchange", () => {
      this.trySuggestRotate();
      this.setState({
        width: document.documentElement.clientWidth - 275,
        height: document.documentElement.clientHeight,
      });
      Matter.Body.setPosition(this.wallBottom, {
        x: this.state.width / 2,
        y: this.state.height + 300,
      });
      Matter.Body.setPosition(this.wallRight, {
        x: this.state.width + 525,
        y: this.state.height / 2,
      });
      Matter.Body.setPosition(this.wallTop, {
        x: this.state.width / 2,
        y: -435,
      });
      Matter.Body.setPosition(this.wallLeft, {
        x: -500,
        y: this.state.height / 2,
      });
      this.liveRender.canvas.width = this.state.width;
      this.liveRender.canvas.height = this.state.height;
    });
    /* This code is highly inefficient and slows performance. */
    // document.addEventListener("mousedown", () => {
    //   this.setState({ ...this.state, cursor: "grabbing" });
    // });
    // document.addEventListener("mouseup", () => {
    //   this.setState({ ...this.state, cursor: "grab" });
    // });
  }

  setPath = (path) => {
    this.setState({
      pathCopy: path,
    });
  };

  setAudio = (context, source) => {
    this.setState({
      audioContext: context,
      source: source,
    });
  };

  renderScene() {
    var Engine = Matter.Engine,
      Render = Matter.Render,
      MouseConstraint = Matter.MouseConstraint,
      Mouse = Matter.Mouse,
      World = Matter.World,
      Bodies = Matter.Bodies;

    var engine = Engine.create({
      //positionIterations: 20,
    });

    engine.timing.timeScale = 1;

    engine.world.gravity.x = 0;
    engine.world.gravity.y = 0;

    var render = Render.create({
      element: this.refs.scene,
      engine: engine,
      options: {
        width: this.state.width,
        height: this.state.height,
        wireframes: false,
        background: "#13131a",
        pixelRatio: 1.0,
      },
    });

    let FreqBand = Bodies.circle(54, 105, this.bandRadius, {
      restitution: this.bandRestitution,
      render: {
        fillStyle: "#ff6600",
        sprite: {
          texture: Band,
        },
      },
    });

    // let cactus = Matter.Bodies.rectangle(800, 350, 80, 110, {
    //   restitution: this.bandRestitution,
    //   render: {
    //     fillStyle: "#ff6602",
    //     sprite: {
    //       texture: Cactus,
    //     },
    //   },
    // });

    let js = Matter.Bodies.rectangle(800, 350, 70, 100, {
      restitution: this.bandRestitution,
      render: {
        fillStyle: "#ff6602",
        sprite: {
          texture: JS,
        },
      },
    });

    let rose = Bodies.circle(800, 300, this.bandRadius, {
      restitution: this.bandRestitution,
      render: {
        fillStyle: "#ff6602",
        sprite: {
          texture: Rose,
        },
      },
    });

    let diagram = Matter.Bodies.rectangle(800, 350, 70, 100, {
      restitution: this.bandRestitution,
      render: {
        fillStyle: "#ff6601",
        sprite: {
          texture: Diagram,
        },
      },
    });

    World.add(engine.world, [
      // bottom
      this.wallBottom,
      // right
      this.wallRight,
      // top
      this.wallTop,
      // left
      this.wallLeft,
    ]);

    let recycle = Matter.Bodies.rectangle(800, 350, 70, 100, {
      restitution: this.bandRestitution,
      collisionFilter: { group: -1 },
      render: {
        fillStyle: "#0175d4",
        sprite: {
          texture: Recycle,
        },
      },
    });

    let spaceship = Matter.Bodies.rectangle(800, 350, 24, 100, {
      restitution: this.bandRestitution,
      collisionFilter: { group: -1 },
      render: {
        fillStyle: "#13131a",
        sprite: {
          texture: DJ,
        },
      },
    });
    let turntable = Matter.Bodies.circle(800, 300, 55, {
      restitution: this.bandRestitution,
      render: {
        fillStyle: "#00cc00",
        sprite: {
          texture: SpaceShip,
        },
      },
    });

    var constraint = Matter.Constraint.create({
      bodyA: spaceship,
      pointA: { x: 0, y: 0 },
      bodyB: turntable,
      stiffness: 0.15,
      damping: 0.2,
    });

    let speaker = Matter.Bodies.rectangle(800, 350, 70, 100, {
      restitution: this.bandRestitution,
      render: {
        fillStyle: "#fff",
        sprite: {
          texture: Speaker,
          yOffset: 0.02,
        },
      },
    });

    World.add(engine.world, [FreqBand]);

    if (document.documentElement.clientWidth > logoBreakpoint) {
      World.add(engine.world, [
        rose,
        speaker,
        // cactus,
        diagram,
        js,
        spaceship,
        turntable,
        constraint,
        recycle,
      ]);
      Matter.Body.rotate(speaker, 1);
    }

    // add mouse control
    var mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.1,
          render: {
            visible: false,
          },
        },
      });

    let newBand = Bodies.circle(54, 105, this.bandRadius, {
      restitution: this.bandRestitution,
      render: {
        fillStyle: "#ff6600",
        sprite: {
          texture: Band,
        },
      },
    });

    this.liveRender = render;

    this.setState({
      FreqBand: FreqBand,
      newBand: newBand,
    });

    World.add(engine.world, mouseConstraint);

    Matter.Runner.run(engine);

    Render.run(render);
  }

  setCurrentRow = (num) => {
    this.setState({
      currentRow: num,
    });
  };

  applySpectrumBackground = () => {
    if (this.liveRender.options.background !== "transparent") {
      // eslint-disable-next-line
      this.liveRender.options.background = "transparent";
    }
  };

  render() {
    return (
      <>
        <div
          style={{
            overflow: "hidden",
            overflowY: "hidden",
            cursor: this.state.cursor,
            zIndex: 1,
            fontDisplay: "optional",
            marginLeft: "-22px",
          }}
          ref="scene"
          id="scene"
        />
        <LegalDialog />
        <MakeSound
          render={this.liveRender}
          FreqBand={this.FreqBand}
          setAudio={this.setAudio}
          ball={this.state.ball}
          pathCopy={this.state.pathCopy}
          setPath={this.setPath}
          currentRow={this.state.currentRow}
          setCurrentRow={this.setCurrentRow}
          newBand={this.state.newBand}
          applySpectrumBackground={this.applySpectrumBackground}
          analyserNode={this.state.analyser}
          setStateForAnalyser={this.setStateForAnalyser}
          frequencyBandArray={this.state.frequencyBandArray}
          getFrequencyData={this.getFrequencyData}
          bandRadius={this.bandRadius}
          bandRestitution={this.bandRestitution}
          loopCountdown={this.state.loopCountdown}
          loopCountdownInit={this.loopCountdownInit}
          setLoopcountdown={(num) =>
            this.setState({
              loopCountdown: num,
            })
          }
          initializeLoopCountdown={() =>
            this.setState({
              loopCountdown: this.loopCountdownInit,
            })
          }
          spectrumClass={this.props.spectrumClass}
          setFilterSvg={this.props.setFilterSvg}
        />
      </>
    );
  }
}
export default MatterScene;
