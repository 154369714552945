import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MatterScene from "../Primary/MatterScene";
import NoSleep from "nosleep.js";

export const logoBreakpoint = 817;

const drawerWidth = 240;

const filters = [
  {
    filter: "allpass",
  },
  {
    filter: "lowpass",
  },
  {
    filter: "highpass",
  },
  {
    filter: "bandpass",
  },
  {
    filter: "notch",
  },
  {
    filter: "lowshelf",
  },
  {
    filter: "highshelf",
  },
  {
    filter: "peaking",
  },
];

export default function PersistentDrawerRight() {
  const [height, setHeight] = useState(document.documentElement.clientHeight);
  const [spectrumColorToggle, setSpectrumColorToggle] = useState(true);
  const [spectrumClass, setSpectrumClass] = useState("");
  const [spectrumVariant, setSpectrumVariant] = useState("");
  const [firstUpdate, setFirstUpdate] = useState(true);
  const handleSetSpectrumColor = () => {
    const setGreen = () => {
      setSpectrumColorToggle(true);
      window.spectrumColorCalculator = (num) => `rgb(0, ${num}, 200)`;
      setSpectrumVariant("green");
      setSpectrumClass("#00fac8");
      localStorage.setItem("spectrumColor", "green");
    };
    const setPurple = () => {
      setSpectrumColorToggle(false);
      window.spectrumColorCalculator = (num) => `rgb(${num}, 0, 200)`;
      setSpectrumVariant("purple");
      setSpectrumClass("#dd00ff");
      localStorage.setItem("spectrumColor", "purple");
    };
    if (firstUpdate === true) {
      if (localStorage.getItem("spectrumColor") === "purple") {
        setPurple();
      } else if (localStorage.getItem("spectrumColor") === "green") {
        setGreen();
      } else {
        setGreen();
      }
      setFirstUpdate(false);
    } else if (spectrumColorToggle) {
      setPurple();
    } else {
      setGreen();
    }
  };
  useEffect(() => {
    window.addEventListener("resize", (e) =>
      setHeight(document.documentElement.clientHeight)
    );
    window.addEventListener("orientationchange", (e) =>
      setHeight(document.documentElement.clientHeight)
    );
    handleSetSpectrumColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Major Mono Display",
      overflow: "hidden",
      display: "flex",
      height: height,
      userSelect: "none",
      fontDisplay: "optional",
      background: "#13131a",
      width: "100vw",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: "#00050c",
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
      maxHeight: "64px",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    title: {
      flexGrow: 1,
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
      color: "#7f7aff",
    },
    toolbar: {
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    hide: {
      display: "none",
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    currentFilterSvg: {
      position: "absolute",
      right: 40,
      top: 1,
      cursor: "context-menu",
    },
    filterSvg: {
      width: "4rem",
      height: "100%",
      display: "flex",
      position: "relative",
      alignItems: "center",
      marginRight: ".9rem",
      marginLeft: ".8rem",
    },
  }));
  const classes = useStyles();

  const [renderLogo, setRenderLogo] = useState(
    document.documentElement.clientWidth > logoBreakpoint
  );

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (document.documentElement.clientWidth <= logoBreakpoint) {
        setRenderLogo(false);
      } else if (
        !renderLogo &&
        document.documentElement.clientWidth > logoBreakpoint
      ) {
        setRenderLogo(true);
      }
    });
    window.addEventListener("orientationchange", (e) => {
      if (document.documentElement.clientWidth <= logoBreakpoint) {
        setRenderLogo(false);
      } else if (
        !renderLogo &&
        document.documentElement.clientWidth > logoBreakpoint
      ) {
        setRenderLogo(true);
      }
    });
  }, [renderLogo]);

  // eslint-disable-next-line no-unused-vars
  const noSleep = useRef(new NoSleep());
  useEffect(() => {
    // Enable wake lock.
    // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
    document.addEventListener(
      "click",
      function enableNoSleep() {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.current.enable();
      },
      false
    );
  }, []);

  const [filterSvg, setFilterSvg] = useState(
    filters.findIndex(
      (f) => String(f.filter) === localStorage.getItem("filter") ?? "allpass"
    )
  );

  return (
    <div className={classes.root}>
      {filterSvg}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: true,
        })}
        onClick={(e) => {
          if (!renderLogo) {
            const target = e.currentTarget;
            const rect = target.getBoundingClientRect();
            const bottomBorderWidth = 10;

            // Calculate the y position of the click inside the element
            const clickYPositionInsideElement = e.clientY - rect.top;

            // Check if the click was within the bottom border area
            if (clickYPositionInsideElement > rect.height - bottomBorderWidth) {
              handleSetSpectrumColor();
            }
          }
        }}
      >
        <Toolbar className={classes.toolBar}>
          {renderLogo && (
            <>
              <span className="logo" />
              <span
                onClick={() => handleSetSpectrumColor()}
                className={`u spectrum--${spectrumVariant}`}
              />
              <Typography variant="h4" noWrap className={classes.title}>
                pl&nbsp;y With so&nbsp;nd
              </Typography>
              <span className={`pyramid pyramid--${spectrumVariant}`} />
              <span className={classes.filterSvg}>
                {filterSvg === 0 ? (
                  <svg
                    fill="#00cc00"
                    width="64px"
                    height="64px"
                    viewBox="0 0 256 137"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M25 67.995A4.002 4.002 0 0 1 29.004 64H227a4 4 0 0 1 4 3.995v10.01A3.99 3.99 0 0 1 227 82H29a4 4 0 0 1-4-3.995v-10.01z"
                        fillRule="evenodd"
                      ></path>
                    </g>
                  </svg>
                ) : filterSvg === 1 ? (
                  <svg
                    fill="#00cc00"
                    width="64px"
                    height="64px"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M24.22 67.796a3.995 3.995 0 0 1 4.008-3.991h85.498c8.834 0 19.732 6.112 24.345 13.657l53.76 87.936c3.46 5.66 11.628 10.247 18.256 10.247h16.718a3.996 3.996 0 0 1 3.994 4.007v8.985a4.007 4.007 0 0 1-4.007 4.008h-24.7c-8.835 0-19.709-6.13-24.283-13.683l-52.324-86.4c-3.43-5.665-11.577-10.257-18.202-10.257H28.214a3.995 3.995 0 0 1-3.993-3.992V67.796z"
                        fillRule="evenodd"
                      ></path>
                    </g>
                  </svg>
                ) : filterSvg === 2 ? (
                  <svg
                    fill="#00cc00"
                    width="64px"
                    height="64px"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M231.007 68.729c0-2.206-1.787-4.995-4.007-4.995h-85.499c-6.466 0-19.531 7.705-22.66 15.97l-55.92 85.647c-3.624 5.55-11.93 10.05-18.559 10.05H28.167c-2.206 0-3.994 2.787-3.994 5.007v8.985a4.005 4.005 0 0 0 3.998 4.007h22.713c8.832 0 20.495-8.703 23.588-16.987l56.167-84.189c3.68-5.517 12.04-9.99 18.668-9.99h77.695c2.212 0 4.005-2.797 4.005-4.994v-8.51z"
                        fillRule="evenodd"
                      ></path>
                    </g>
                  </svg>
                ) : filterSvg === 3 ? (
                  <svg
                    fill="#00cc00"
                    width="64px"
                    height="64px"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M25.344 180.07a4.008 4.008 0 0 1 3.997-4.01h16.996c6.631 0 14.517-4.753 17.611-10.614l47.246-89.476c9.282-17.579 24.376-17.602 33.72-.042l47.637 89.532c3.115 5.855 11.007 10.6 17.65 10.6h16.489a4.01 4.01 0 0 1 4.001 4.01v8.809c0 2.214-1.8 4.009-4.007 4.009h-24.49c-8.838 0-19.361-6.32-23.513-14.133L136.446 99.28c-4.665-8.778-12.228-8.772-16.887 0l-42.21 79.475c-4.145 7.805-14.667 14.133-23.508 14.133h-24.49a4.012 4.012 0 0 1-4.007-4.01v-8.808z"
                        fillRule="evenodd"
                      ></path>
                    </g>
                  </svg>
                ) : filterSvg === 4 ? (
                  <svg
                    fill="#00cc00"
                    width="64px"
                    height="64px"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M25.101 77.628a4.008 4.008 0 0 0 3.997 4.01h16.996c6.632 0 13.927 5.01 16.3 11.202l52.724 85.231c7.115 18.564 18.693 18.571 25.857.025L193.91 92.84c2.39-6.187 9.693-11.202 16.336-11.202h16.49a4.01 4.01 0 0 0 4-4.01V68.82a4 4 0 0 0-3.994-4.009h-23.508c-8.835 0-18.547 6.702-21.69 14.962l-47.147 73.852c-3.533 9.287-9.217 9.262-12.694-.051L75.2 79.805C72.108 71.524 62.44 64.81 53.6 64.81H29.11a4.012 4.012 0 0 0-4.008 4.01v8.808z"
                        fillRule="evenodd"
                      ></path>
                    </g>
                  </svg>
                ) : filterSvg === 5 ? (
                  <svg
                    fill="#00cc00"
                    width="64px"
                    height="64px"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M52.893 63c8.835 0 21.563 4.505 28.433 10.063l47.527 38.445c3.5 2.83 9.024 5.241 14.29 6.492h83.408a4.01 4.01 0 0 1 3.99 3.705v11.643a4.01 4.01 0 0 1-3.99 3.705h-83.408c-5.266 1.251-10.79 3.661-14.29 6.492L81.326 181.99c-6.87 5.558-19.598 10.063-28.433 10.063h-23.51a4 4 0 0 1-3.994-4.008v-8.984a4.003 4.003 0 0 1 4.007-4.008h17.495c6.617 0 16.201-3.341 21.388-7.463l43.906-34.897c1.846-1.713 3.845-3.666 5.682-5.168-1.97-1.599-3.762-3.375-5.682-5.166L68.28 87.463C63.092 83.34 53.508 80 46.891 80H29.396a4.003 4.003 0 0 1-4.007-4.008v-8.984A4 4 0 0 1 29.383 63z"></path>
                    </g>
                  </svg>
                ) : filterSvg === 6 ? (
                  <svg
                    fill="#00cc00"
                    width="64px"
                    height="64px"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M203.037 63c-8.835 0-21.563 4.505-28.433 10.063l-47.528 38.445c-3.5 2.83-9.024 5.241-14.289 6.492H29.38a4.01 4.01 0 0 0-3.99 3.705v11.643a4.01 4.01 0 0 0 3.99 3.705h83.408c5.265 1.251 10.79 3.661 14.29 6.492l47.527 38.445c6.87 5.558 19.598 10.063 28.433 10.063h23.51a4 4 0 0 0 3.994-4.008v-8.984a4.003 4.003 0 0 0-4.008-4.008H209.04c-6.617 0-16.202-3.341-21.389-7.463l-43.906-34.897c-1.846-1.713-3.845-3.666-5.682-5.168 1.972-1.599 3.762-3.375 5.682-5.166l43.906-34.896C192.837 83.34 202.422 80 209.04 80h17.493a4.003 4.003 0 0 0 4.008-4.008v-8.984A4 4 0 0 0 226.547 63z"></path>
                    </g>
                  </svg>
                ) : filterSvg === 7 ? (
                  <svg
                    fill="#00cc00"
                    width="64px"
                    height="64px"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M25.856 160.231l-.105 15.5 21.52.091s10.258.899 17.47-1.033c7.21-1.932 5.846.283 11.266-6.664s2.59-5.662 5.685-15.063c3.095-9.402 5.482-18.859 5.482-18.859l5.383-19.944 6.906-17.103 4.976-5.127 11.477-.617 16.25.1 18.06-.211 6.094.464 5.18 7.82 4.468 14.117 4.062 14.727 4.04 14.208 4.367 14.726s2.14 7.77 6.398 11.62c4.257 3.851 13.406 6.293 13.406 6.293l20.313.3 13.651-.105.502-15.884-16.709.405-17.022-.72-2.563-.717-5.742-17.059-6.713-23.695-5.777-19.032s-1.753-7.91-6.973-13.517c-5.22-5.607-8.141-8.08-15.059-10.146-6.917-2.066-10.042-.902-21.245-.803-11.202.099-17.124.015-22.405.19-5.281.174-10.457.896-10.457.896l-9.33 3.96-8.1 11.07-5.023 12.188-5.23 18.891-3.999 14.727-4.511 13.608-3.282 9.445-17.84.793-18.87.16z"
                        fillRule="evenodd"
                      ></path>
                    </g>
                  </svg>
                ) : null}
              </span>
            </>
          )}
        </Toolbar>
        <span className={classes.currentFilterSvg} id="filter-svg" />
      </AppBar>
      <MatterScene spectrumClass={spectrumClass} setFilterSvg={setFilterSvg} />
    </div>
  );
}
