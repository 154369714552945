import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function Visualizer(props) {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => setHeight(window.innerHeight);
    window.addEventListener("resize", updateHeight);
    window.addEventListener("orientationchange", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
      window.removeEventListener("orientationchange", updateHeight);
    };
  }, []);

  const useStyles = makeStyles(() => ({
    spectrum: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      backgroundColor: "#13131A",
      backgroundImage: `
        linear-gradient(0deg, rgba(180,180,180,0.1) 1px, transparent 1px), /* Major horizontal lines */
        linear-gradient(90deg, rgba(180,180,180,0.1) 1px, transparent 1px), /* Major vertical lines */
        linear-gradient(0deg, rgba(180,180,180,0.07) 1px, transparent 1px), /* Minor horizontal lines */
        linear-gradient(90deg, rgba(180,180,180,0.07) 1px, transparent 1px) /* Minor vertical lines */
      `,
      backgroundSize: `
        300px 300px,   /* Size of major grid square */
        300px 300px,   /* Size of major grid square */
        50px 50px,     /* Size of minor grid within major squares */
        50px 50px      /* Size of minor grid within major squares */
      `,
      transform: "rotatex(180deg)",
      height: height,
      overflowY: "hidden",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.spectrum}>
      {props.frequencyBandArray.map((num) => (
        <span id={num} key={num} />
      ))}
    </div>
  );
}
