import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App";
import * as serviceWorker from "./serviceWorker";

/* 

LICENSES

Matter.js https://github.com/liabru/matter-js/blob/master/LICENSE

Rose SVG https://www.svgrepo.com/page/licensing#MIT

Filter SVGs https://www.svgrepo.com/page/licensing#MIT

Music and audio files are not licensed.

*/

ReactDOM.render(<App />, document.getElementById("root"));
console.log(`This app uses licenses from these repositories:
    https://github.com/babel/babel
    https://github.com/mui-org/material-ui
    https://github.com/netlify/plugin-csp-nonce
    https://github.com/lukeed/clsx
    https://github.com/liabru/matter-js
    https://github.com/richtr/NoSleep.js
    https://github.com/facebook/prop-types
    https://github.com/YIZHUANG/react-csp
    https://github.com/facebook/react
    https://github.com/facebook/create-react-app
    https://github.com/Microsoft/TypeScript`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
